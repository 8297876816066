@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

textarea {
	field-sizing: content;
}

// Buttons
.button {
	@apply px-4 py-2.5 text-sm font-medium rounded-lg text-center border;
}

.btn-blue {
	@apply button text-white bg-blue-700 border-blue-700 focus:ring-2 focus:ring-blue-300;
}

.btn-blue-outline {
	@apply button text-blue-700 border-blue-700  focus:ring-2 focus:outline-none focus:ring-blue-300;
}

.btn-red {
	@apply button text-white bg-red-700 border-red-700 focus:ring-2 focus:ring-red-300;
}

.btn-red-outline {
	@apply button text-red-700 border-red-700  focus:ring-2 focus:outline-none focus:ring-red-300;
}

.btn-green {
	@apply button text-white bg-green-700 border-green-700 focus:ring-2 focus:ring-green-300;
}

.btn-yellow {
	@apply button text-white bg-yellow-700 border-yellow-700 focus:ring-2 focus:ring-yellow-300;
}

.btn-yellow-outline {
	@apply button text-yellow-700 border-yellow-700 focus:ring-2 focus:outline-none focus:ring-yellow-300;
}

.btn-gray-outline {
	@apply button text-gray-800 border-gray-200 focus:ring-2 focus:ring-blue-300;
}
// End Buttons

.form-input {
	@apply block w-full p-2.5 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500;
}

.form-input-error {
	@apply block w-full p-2.5 text-sm text-gray-900 border border-red-500 rounded-lg bg-red-50 focus:ring-red-500 focus:border-red-500;
}

.form-checkbox {
	@apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2;
}

.form-select {
	@apply block w-full p-2.5 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500;
}

.form-select-error {
	@apply block w-full p-2.5 text-sm text-gray-900 border border-red-500 rounded-lg bg-red-50 focus:ring-red-500 focus:border-red-500;
}

.form-textarea {
	@apply block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500;
}

.form-label {
	@apply block mb-2 text-sm font-medium text-gray-900;
}
// Forms

.swiper-pagination-bullet {
	background-color: black !important;
	width: 12px !important;
	height: 12px !important;
}

.swiper-button-disabled {
	@apply bg-gray-400;
	cursor: not-allowed;
}

.clear-btn:focus {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
		var(--tw-ring-offset-color) !important;
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width))
		var(--tw-ring-color) !important;
	box-shadow:
		var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
	--tw-ring-opacity: 1;
	--tw-ring-color: rgb(164 202 254);
	outline: 2px solid transparent !important;
	outline-offset: 2px !important;
}
.datepicker-picker {
	box-shadow: none !important;
	border-width: 1px !important;
	border-color: rgb(229 231 235) !important;
}

.dropdown-content {
	visibility: hidden;
	transform: scale(0.9);
	opacity: 0;
	z-index: -10;
	transition: all 100ms ease;
}

.open > .dropdown-content {
	visibility: visible;
	opacity: 1 !important;
	transform: scale(1) !important;
	transition: all 0.1s ease !important;
	z-index: 100 !important;
}

.tox-statusbar__branding {
	display: none !important;
}

.tox-promotion {
	display: none !important;
}

@media screen and (min-width: 768px) {
	.tooltip-directive {
		position: fixed;
		z-index: 1000;
	}

	.tooltip-content {
		position: absolute;
		left: 50%;
		top: -1rem;
		transform: translateX(-50%) translateY(-100%);
		background: rgb(17, 24, 39);
		color: #fff;
		text-align: center;
		border-radius: 5px;
		padding: 0.7rem;
		width: max-content;
		min-width: 160px;
		max-width: 250px;
		z-index: 1000;
	}

	.tooltip-header {
		font-size: 12px;
		text-align: left;
		overflow: hidden;
		margin-bottom: 4px;
	}

	.tooltip-body {
		font-size: 12px;
		text-align: left;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.tooltip-directive::after {
		content: '';
		position: absolute;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #2b222a;
		transform: translateX(-50%) translateY(calc(-100% - 9px));
	}
}

.modal {
	transform: translateX(50px);
	opacity: 0;
	z-index: -1;
}

.modal-show {
	z-index: 200;
	transform: translateX(0);
	opacity: 1;
	transition: all 300ms ease;
}

.toast-container {
	display: grid;
	gap: 5px;
	z-index: 9999;
	position: fixed;
	top: 20px;
	right: 20px;
	margin: 0 !important;
	padding: 0 !important;
}

.ngx-toastr {
	background-color: white !important;
	color: black !important;
	margin: 0 !important;
	width: 300px;
	padding: 0 !important;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.toast-progress {
	opacity: 1;
}

// Popover
.popover-arrow:before {
	background: inherit;
	height: 8px;
	position: absolute;
	width: 8px;
}

.popover-arrow:after,
.popover-arrow:before {
	content: '';
	transform: rotate(45deg);
	visibility: visible;
}

.popover-arrow:before {
	border-color: #e5e7eb;
	border-style: solid;
}

.popover-arrow:before,
.popover-arrow:after {
	border-bottom-width: 1px;
	border-right-width: 1px;
}

.popover-arrow:after {
	background: inherit;
	height: 9px;
	position: absolute;
	width: 9px;
}

// End Popover

.cdk-drop-list-dragging .cdk-drag {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
	transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}
